import * as React from 'react';
import {
  /* HashRouter, */
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Link,
} from 'react-router-dom';
import {
  Field,
  Formik,
  Form
} from 'formik';
import * as yup from 'yup';

import './App.css';
import { sendResetLink, setPassword } from './backend';
import { useQueryParams } from './util';


const ResetPasswordSchema = yup.object().shape({
  password: yup.string()
               .required('required')
               .min(8, 'must be at least 8 characters'),
  confirmPassword: yup.string()
                      .required('please retype your password to confirm')
                      .oneOf([yup.ref('password')], 'Does not match')
});

const useTitle = (title: string) =>
    React.useEffect(() => {
      document.head.title = `Scenthound: ${title}`;
    }, [title]);


function ResetLinkSent() {
  const { email } = useQueryParams();

  return (
    <div>
      <h1>Reset Link Set</h1>

      <p>
        A link to reset your password has been sent to <strong>{email}</strong>.
      </p>
    </div>
  );
}

function ForgotPassword() {
  const { email } = useQueryParams();
  const nav = useNavigate();
  useTitle('Forgot Password');

  return (
    <div>
      <Formik initialValues={{ email }}
              enableReinitialize
              validateOnBlur={false}
              onSubmit={async (values, actions) => {
                const response = await sendResetLink(values.email);

                if (response.ok) {
                  nav(`/link_sent?email=${encodeURIComponent(values.email)}`, { });
                } else {
                  const data = await response.json();
                  actions.setFieldError('email', "There's no account associated with this address.")
                  console.log(data);
                }
              }}
        >
       {({ errors, isSubmitting }) => (
         <Form>
           <h3>Forgot Password</h3>
           <p>
           </p>
           <div className={'field email ' + (errors.email ? 'error' : '')}>
             <label htmlFor="email">
               Email
               {errors.email ?
                <div className="errorMessage">{errors.email}</div>
               : null}
             </label>
             <Field id="email" name="email"
                    autoFocus readOnly={false} />
           </div>
           <div className="buttons">
             <button type="submit" disabled={isSubmitting}>
               Send Reset Link
             </button>
           </div>
         </Form>
       )}
      </Formik>
    </div>
  );
}

function PasswordResetComplete() {
  useTitle('Password Changed');

  return (
    <div>
      <h1>Password Reset</h1>

      <p>
        Your password was successfully changed.
      </p>
    </div>
  );
}

function ResetPassword() {
  const { token, email } = useQueryParams();
  const nav = useNavigate();

  useTitle('Change Your Password');

  React.useEffect(() => {
    if (!token)
      window.location.href = 'https://link.scenthound.com';
  }, [token]);

  if (!token) {
    return <p>
      Missing 'token' parameter
    </p>
  }

  const initialValues = {
    token,
    username: email,
    password: '',
    confirmPassword: '',
  };

  return (
    <div>
      <Formik initialValues={initialValues}
              validationSchema={ResetPasswordSchema}
              validateOnBlur={false}
              onSubmit={async (values, actions) => {
                try {
                  await setPassword(values.token, values.password).then(
                    async (response) => {
                      if (response.ok) {
                        nav('/complete');
                      } else {
                        const data = await response.json();
                        console.warn('error:', data);

                        if (data.type === 'mytime') {
                          if (data.errors.token)
                            actions.setFieldError('token', data.errors.token[0].type);
                        }
                        actions.setFieldError('password', 'Something went wrong');
                      }
                    }
                  );
                } catch (err: any) {
                  console.warn(err);
                  actions.setFieldError('password', err.message);
                }
              } }>
        {({ values, errors, touched, isSubmitting, isValid }) => (
        <Form>
          <h3>Reset your password</h3>
          <Field type="hidden" name="token" value={token} readOnly />
          <Field type="hidden" name="username" />
          {errors.token ?
           errors.token === 'is_invalid_option' ?
           <div>
             This reset link is no longer valid.
             {values.username ?
              <>
                {' '}
                <Link to={{ pathname: '/forgot', search: `?email=${encodeURIComponent(values.username)}`}}>
                  Request another reset link.
                </Link>
              </> :
              ''}
           </div>
          : null
          : null}

          <div className={'field password ' + (errors.password && touched.password ? 'error' : '')}>
            <label htmlFor="password">
              New Password
              {errors.password ?
               <div className="errorMessage">{errors.password}</div>
              : null}
            </label>
            <Field id="password" name="password" type="password"
                   autoFocus readOnly={isSubmitting} />
          </div>

          <div className={'field confirmPassword ' + (errors.confirmPassword ? 'error' : '')}>
            <label htmlFor="confirmPassword">
              Retype Password
              {errors.confirmPassword ?
               <div className="errorMessage">
                 {errors.confirmPassword}
               </div>
              : null}
            </label>
            <Field id="confirmPassword" name="confirmPassword"
                   type="password"
                   readOnly={isSubmitting}
            />
          </div>

          <div className="buttons">
            <button type="submit" disabled={!isValid || isSubmitting}>
              Reset Password
            </button>
          </div>
        </Form>
        )}
      </Formik>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <div className="content">
        <BrowserRouter >
          <Routes>
            <Route path="/complete" element={<PasswordResetComplete />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/link_sent" element={<ResetLinkSent />} />
            <Route path="/" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
