import config from './config';
const { lambdaRoot } = config;

export const ChangePasswordURL = `${lambdaRoot}/${config.changeEndpoint}`;
export const ForgotPasswordURL = `${lambdaRoot}/${config.forgotEndpoint}`;


export function setPassword(token: string, password: string) {
  return fetch(ChangePasswordURL, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ token, password })
  });
}

export function sendResetLink(email: string) {
  return fetch(ForgotPasswordURL, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ email })
  });
}
