import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { splitFirst } from './util';

const rootElt = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(rootElt).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

function redirectHashUrl() {
  const { hash, pathname, search } = window.location;

  if (!(pathname === '' || pathname === '/')) return;
  if (!hash) return;

  const [hashpath_, hashSearch] = splitFirst(hash, '?');
  const hashpath = hashpath_.replace(/^#+/, '');

  if (!hashpath) return;

  const params = new URLSearchParams(search + '&' + hashSearch);
  window.location.href = hashpath + (params.size ? `?${params}` : '');
}

redirectHashUrl();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
