const targets = require('./targets.json');

const env = window.location.hostname === 'app.scenthound.com' ?
  'production' : 'development';

const config = Object.assign(
  targets.defaults,
  targets.env[env]
);

export default config;
