import { useLocation, } from 'react-router-dom';


export function splitFirst(s: string, sep: string) {
  const idx = s.indexOf(sep);
  return [s.slice(0, idx), idx > -1 ? s.slice(idx+sep.length) : ''];
}

export function mergeParams(search: string, hash: string ) {
  const [, hashSearch] = splitFirst(hash, '?');
  const params = new URLSearchParams(search + '&' + hashSearch);

  return Array.from(params).reduce((p, [k, v]) => {
    p[k] = v; // Ignore multi-valued params
    return p;
  }, {} as { [k in string]: string });
}

export function useQueryParams() {
  const { search, hash } = useLocation();
  return mergeParams(search, hash);
}

